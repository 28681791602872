declare global {
	interface String {
		replaceFromMap(this: string, map: Record<string, string>): string
	}
}

export default String.prototype.replaceFromMap = function (this: string, map: Record<string, string>) {
	if (!map) return this
	const regex = new RegExp(Object.keys(map).join('|'), 'g')
	return this.replace(regex, (m) => map[m] || m)
}
