import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

type Dayjs = ReturnType<typeof dayjs>
type DateType = Date


type TYear         = `${number}${number}${number}${number}`;
type TMonth        = `${number}${number}`;
type TDay          = `${number}${number}`;
type THours        = `${number}${number}`;
type TMinutes      = `${number}${number}`;
type TSeconds      = `${number}${number}`;
type TMilliseconds = `${number}${number}${number}`;

/**
 * Represent a string like `2021-01-08`
 */
type TDateISODate = `${TYear}-${TMonth}-${TDay}`;

/**
 * Represent a string like `14:42:34.678`
 */
type TDateISOTime = `${THours}:${TMinutes}:${TSeconds}.${TMilliseconds}`;

/**
 * Represent a string like `14:42`
 */
type DateTimeLocalTime = `${THours}:${TMinutes}`;

/**
 * Represent a string like `2021-01-08T14:42:34.678Z` (format: ISO 8601).
 *
 * It is not possible to type more precisely (list every possible values for months, hours etc) as
 * it would result in a warning from TypeScript:
 *   "Expression produces a union type that is too complex to represent. ts(2590)
 */
type TDateISO = `${TDateISODate}T${TDateISOTime}Z`;
type DateTimeLocalString = `${TDateISODate}T${DateTimeLocalTime}`

export { type DateTimeLocalString }

type DateLike = string | DateType | Dayjs

export function returnDayjs(value: DateLike, toTimeZone:string|false = 'America/New_York') {
	if(toTimeZone) return dayjs(value).tz(toTimeZone, true).second(0).millisecond(0)
	else return dayjs(value).second(0).millisecond(0)
}
export function roundToInterval(dateLike: DateLike, interval: number = 60, mode: 'lazy' | 'greedy' | 'balanced' = 'lazy') {
	let dayjsDate = returnDayjs(dateLike)

	let firstInterval
	if (mode === 'lazy') firstInterval = interval * 1.25
	else if (mode === 'greedy') firstInterval = interval * .75

	if (firstInterval) dayjsDate = roundToInterval(dayjsDate, firstInterval, 'balanced');

	const roundedMinute = Math.round(dayjsDate.minute() / interval) * interval;
	return dayjsDate.startOf('hour').add(roundedMinute, 'minute');
}
export function roundAtMinute(dateLike: DateLike, threshold: number = 45) {
	let dayjsDate = returnDayjs(dateLike)

	const minutes = dayjsDate.minute()
	dayjsDate = dayjsDate.startOf('hour')
	if (minutes >= threshold) dayjsDate = dayjsDate.add(1,'hour')

	return dayjsDate
}
type MinDateProps = {
	dateLike: DateLike;
	buffer?: number;
	threshold?: number;
}
export function minDate({dateLike, buffer = 2, threshold = 45}: MinDateProps) {
	const dayjsDate = returnDayjs(dateLike);
	let roundedDate = roundAtMinute(dayjsDate, threshold);
	return roundedDate.add(buffer, 'hours');
}
type AppointmentDateObj =  {
	dayjs: Dayjs;
	date: Date;
	dateTimeLocal: string;
	utc: string;
}
export class AppointmentRules {
	bufferInHours: number;
	initial: AppointmentDateObj;
	formats = {
		dateTimeLocal: `YYYY-MM-DD[T]HH:mm`
	}
	#makeDateObj(dateLike: DateLike) {
		const dayjsDate = returnDayjs(dateLike);
		const dateObj: AppointmentDateObj = {
			dayjs: dayjsDate,
			date: dayjsDate.toDate(),
			dateTimeLocal: dayjsDate.format(this.formats.dateTimeLocal),
			utc: dayjsDate.toISOString()
		}
		return dateObj
	}
	constructor(arProps?: { dateLike?: DateLike, bufferInHours?: number }) {
		const initialDayjs = (arProps && arProps.dateLike) ? roundAtMinute(arProps.dateLike) : roundAtMinute(dayjs())
		this.bufferInHours = (arProps && arProps.bufferInHours) ? arProps.bufferInHours : 2;
		this.initial = this.#makeDateObj(initialDayjs);
	}

	get min() {
		const minDayjs = minDate({dateLike: this.initial.dayjs, buffer: this.bufferInHours})
		return this.#makeDateObj(minDayjs)
	}
}

export function getExpiry({ date = dayjs(), threshold = 5, format = 'MM/DD/YYYY' }) {
	let dayjsDate = returnDayjs(date)
	if (dayjsDate) {
		const thisDay = dayjsDate.date()
		const daysLeft = dayjsDate.daysInMonth() - thisDay
		if (daysLeft < threshold) dayjsDate = dayjsDate.add(1, 'month')

		return dayjsDate.endOf('month').format(format)
	} else throw Error('getExpiry function was passed invalid date parameter')
}
